import React from "react"

const mailchimp = "\n" +
  "<link href=\"//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css\" rel=\"stylesheet\" type=\"text/css\">\n" +
  "  <style type=\"text/css\">\n" +
  "    #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }\n" +
  "    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n" +
  "    We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" +
  "  </style>\n" +
  "  <style type=\"text/css\">\n" +
  "    #mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: auto;margin-right: 10px;}\n" +
  "    #mergeRow-gdpr {margin-top: 20px;}\n" +
  "    #mergeRow-gdpr fieldset label {font-weight: normal;}\n" +
  "    #mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;}\n" +
  "  </style>\n" +
  "  <div id=\"mc_embed_signup\">\n" +
  "    <form action=\"https://caffeinity.us7.list-manage.com/subscribe/post?u=33297a5f202fc3d0f9903053b&amp;id=ab56453f78\" method=\"post\" id=\"mc-embedded-subscribe-form\" name=\"mc-embedded-subscribe-form\" class=\"validate\" target=\"_blank\" novalidate>\n" +
  "      <div id=\"mc_embed_signup_scroll\">\n" +
  "        <h2>Subscribe</h2>\n" +
  "        <div class=\"indicates-required\"><span class=\"asterisk\">*</span> indicates required</div>\n" +
  "        <div class=\"mc-field-group\">\n" +
  "          <label for=\"mce-EMAIL\">Email Address  <span class=\"asterisk\">*</span>\n" +
  "          </label>\n" +
  "          <input type=\"email\" value=\"\" name=\"EMAIL\" class=\"required email\" id=\"mce-EMAIL\">\n" +
  "        </div>\n" +
  "        <div class=\"mc-field-group\">\n" +
  "          <label for=\"mce-FNAME\">First Name </label>\n" +
  "          <input type=\"text\" value=\"\" name=\"FNAME\" class=\"\" id=\"mce-FNAME\">\n" +
  "        </div>\n" +
  "        <div class=\"mc-field-group\">\n" +
  "          <label for=\"mce-LNAME\">Last Name </label>\n" +
  "          <input type=\"text\" value=\"\" name=\"LNAME\" class=\"\" id=\"mce-LNAME\">\n" +
  "        </div>\n" +
  "        <div id=\"mergeRow-gdpr\" class=\"mergeRow gdpr-mergeRow content__gdprBlock mc-field-group\">\n" +
  "          <div class=\"content__gdpr\">\n" +
  "            <label>Marketing Permissions</label>\n" +
  "            <p>Please select all the ways you would like to hear from Dennis M. Binder:</p>\n" +
  "            <fieldset class=\"mc_fieldset gdprRequired mc-field-group\" name=\"interestgroup_field\">\n" +
  "              <label class=\"checkbox subfield\" for=\"gdpr_53925\"><input type=\"checkbox\" id=\"gdpr_53925\" name=\"gdpr[53925]\" value=\"Y\" class=\"av-checkbox \"><span>Email</span> </label>\n" +
  "            </fieldset>\n" +
  "            <p>You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please visit our website.</p>\n" +
  "          </div>\n" +
  "          <div class=\"content__gdprLegal\">\n" +
  "            <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href=\"https://mailchimp.com/legal/terms\" target=\"_blank\">Learn more about Mailchimp's privacy practices here.</a></p>\n" +
  "          </div>\n" +
  "        </div>\n" +
  "        <div id=\"mce-responses\" class=\"clear foot\">\n" +
  "          <div class=\"response\" id=\"mce-error-response\" style=\"display:none\"></div>\n" +
  "          <div class=\"response\" id=\"mce-success-response\" style=\"display:none\"></div>\n" +
  "        </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->\n" +
  "        <div style=\"position: absolute; left: -5000px;\" aria-hidden=\"true\"><input type=\"text\" name=\"b_33297a5f202fc3d0f9903053b_ab56453f78\" tabindex=\"-1\" value=\"\"></div>\n" +
  "        <div class=\"optionalParent\">\n" +
  "          <div class=\"clear foot\">\n" +
  "            <input type=\"submit\" value=\"Subscribe\" name=\"subscribe\" id=\"mc-embedded-subscribe\" class=\"button\">\n" +
  "              <p class=\"brandingLogo\"><a href=\"http://eepurl.com/hN-vq1\" title=\"Mailchimp - email marketing made easy and fun\"><img src=\"https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg\"></a></p>\n" +
  "          </div>\n" +
  "        </div>\n" +
  "      </div>\n" +
  "    </form>\n" +
  "  </div>\n" +
  "  <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text'; /*\n" +
  " * Translated default messages for the $ validation plugin.\n" +
  " * Locale: DE\n" +
  " */\n" +
  "  $.extend($.validator.messages, {\n" +
  "  required: \"Dieses Feld ist ein Pflichtfeld.\",\n" +
  "  maxlength: $.validator.format(\"Geben Sie bitte maximal {0} Zeichen ein.\"),\n" +
  "  minlength: $.validator.format(\"Geben Sie bitte mindestens {0} Zeichen ein.\"),\n" +
  "  rangelength: $.validator.format(\"Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein.\"),\n" +
  "  email: \"Geben Sie bitte eine gültige E-Mail Adresse ein.\",\n" +
  "  url: \"Geben Sie bitte eine gültige URL ein.\",\n" +
  "  date: \"Bitte geben Sie ein gültiges Datum ein.\",\n" +
  "  number: \"Geben Sie bitte eine Nummer ein.\",\n" +
  "  digits: \"Geben Sie bitte nur Ziffern ein.\",\n" +
  "  equalTo: \"Bitte denselben Wert wiederholen.\",\n" +
  "  range: $.validator.format(\"Geben Sie bitten einen Wert zwischen {0} und {1}.\"),\n" +
  "  max: $.validator.format(\"Geben Sie bitte einen Wert kleiner oder gleich {0} ein.\"),\n" +
  "  min: $.validator.format(\"Geben Sie bitte einen Wert größer oder gleich {0} ein.\"),\n" +
  "  creditcard: \"Geben Sie bitte ein gültige Kreditkarten-Nummer ein.\"\n" +
  "});}(jQuery));var $mcj = jQuery.noConflict(true);</script>"

export function MailchimpSubscribe() {
  return (
    <div className="text-caffeinity-green-dark text-center my-16 max-w-4xl mx-auto">
      <h1 className="text-caffeinity-green-light">Interesse?</h1>
      <h4 className="mb-8 text-gray-600">Neuigkeiten über Kaffee, Bohnen und Zubereitung zu Hause mit unserem <u>Newsletter</u>.</h4>
      <div dangerouslySetInnerHTML={{ __html: mailchimp }}></div>
    </div>
  )
}

