import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function MyHeroSection() {
  return (

<main className="lg:relative">
  <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
    <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
      <h1 className="tracking-tight font-extrabold text-caffeinity-green-dark sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
        <span className="block xl:inline">Wir kennen die passenden </span>{' '}
        <span className="block text-caffeinity-green-light xl:inline break-words">Direct Trade Kaffeebohnen</span>{' '}
        <span className="block xl:inline">für dich.</span>{' '}
      </h1>
      <p className="mt-3 max-w-md mx-auto text-lg text-gray-700 sm:text-xl md:mt-5 md:max-w-3xl">
       Individuell auf deinen Geschmack und Maschine zu Hause abgestimmte Kaffeebohnen von lokalen Speciality Coffee Röstern.
      </p>
      <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
        <div className="rounded-md shadow">
          <Link to="/taste"
                className="btn-primary">
            Caffeinity jetzt testen</Link>
        </div>

      </div>
    </div>
  </div>
  <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
    <StaticImage src="../images/nathan-dumlao-XOhI_kW_TaM-unsplash.jpg"
                 alt="Caffeinity direct trade coffee title image"
      className="absolute inset-0 w-full h-full object-cover"

    />
  </div>
</main>
  )}