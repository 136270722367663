import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

function ProductDescription() {
  return (
    <>

      <div className="max-w-4xl mx-auto sm:px-6 lg:px-8">

        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8 ">
          <h2 className="font-extrabold text-white sm:text-4xl">
            <span className="block text-caffeinity-green-dark">Wie funktioniert das ganze überhaupt?</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-600">
            Du hast deinen ganz persönlichen und individuellen Geschmack sowie deine Lieblingsmethode Kaffee zu Hause
            zuzubereiten. Wir haben den dafür passenden Kaffee. In nur 3 Schritten kannst du dich selbst davon
            überzeugen.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-y-4 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 ">

          <div className="relative flex flex-col mt-8">
            <StaticImage src="../images/nathan-dumlao-pMW4jzELQCw-unsplash_crop.png"
                         alt="Egal wie dein Geschmack ist"
                         className="aspect-h-4 aspect-w-3"
            />
            <div className="md:px-0  flex-1 mx-4">
              <h4 className="text-base font-semibold tracking-wider text-caffeinity-green-light uppercase mt-2">Schritt
                1</h4>
              <h2 className="font-extrabold tracking-tight text-caffeinity-green-dark">
                Dein Geschmack
              </h2>
              <p className="mt-4 text-lg text-gray-600">
                Der erste Schritt zu deinem Kaffee ist die Erstellung deines Geschmacksprofils mit Trinkgewohnheiten,
                Lieblingsgeschmäcker und wie du deinen Kaffee ganz individuell zu Hause zubereitest.
              </p>
            </div>

          </div>

          <div className="relative flex flex-col mt-8">
            <StaticImage src="../images/caleb-minear-kVAs4SVIxSk-unsplash_crop.png"
                         alt="Wir haben die richtigen Kaffeebohnen für deinen Geschmack"
                         className="aspect-h-4 aspect-w-3"
            />
            <div className="md:px-0 flex-1 mx-4">
              <h4 className="text-base font-semibold tracking-wider text-caffeinity-green-light uppercase mt-2">Schritt
                2</h4>
              <h2 className="font-extrabold tracking-tight text-caffeinity-green-dark">
                Deine Bohnen
              </h2>
              <p className="mt-4 text-lg text-gray-600">
                Auf Basis dieser Informationen suchen wir aus all unseren Röstern den passenden Kaffee für deinen
                Geschmack und deine Zubereitungsart zu Hause aus.
              </p>
            </div>
            <Link
              to="todo/"
              className="btn w-48 mx-auto mb-20">
              Mehr Dazu
            </Link>
          </div>

          <div className="relative flex flex-col mt-8">
            <StaticImage src="../images/cafe-latte-at-home-siebtraeger.JPG"
                         alt="CafeLatte zu Hause genießen mit Siebträger und Waage latte art herz"
                         className="aspect-h-4 aspect-w-3"
            />
            <div className="md:px-0 flex-1 mx-4">
              <h4 className="text-base font-semibold tracking-wider text-caffeinity-green-light uppercase mt-2">Schritt
                3</h4>
              <h2 className="font-extrabold tracking-tight text-caffeinity-green-dark">
                Dein Genuss
              </h2>
              <p className="mt-4 text-lg text-gray-600">
                Du entscheidest dich für ein Abonnement Modell und wir senden deinen Kaffee inklusive
                Zubereitungsanleitung zu dir. Nicht überzeugt? Kein Problem du kannst das ganze auch einfach mal für
                ein Monat ohne Bindung und Risiko testen.
              </p>
            </div>

          </div>
        </div>
        <Link
          to="/taste"
          className="btn-primary mt-8">
          Ohne Risiko testen
        </Link>
      </div>

      <div className="relative pt-16 pb-32 overflow-hidden">
        <div className="mt-44 py-24 bg-caffeinity-brown">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div className="mt-6 ">
                <h2 className="font-extrabold tracking-tight">
                  Und der Clou!
                </h2>
                <p className="mt-4 text-lg">
                  Entscheidest du dich für ein Abonnement, bekommst du Monat für Monat eine <b>neue Sorte Kaffee</b>, welche
                  genau auf deinen persönlichen Geschmack abgestimmt ist. Somit kommt nie wieder Langeweile zu Hause
                  auf. …zumindest nicht beim Kaffee.
                </p>
                <div className="mt-6">
                <Link
                  to="/taste"
            className="btn-primary"
          >
                    Geschmacksprofil jetzt erstellen
                  </Link>
                </div>
              </div>
            </div>

            <div className="py-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="lg:pl-4 lg:m-0 lg:relative">

                <StaticImage src="../images/nathan-bingle-K9MaGDSbOTg-unsplash.jpg"
                             alt="Jeden Monat neue Sorten direkt nach Hause geliefert"
                             width={800}
                             height={650}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}


export default ProductDescription