import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function FeatureSection() {
  return (
    <div className="bg-caffeinity-brown">
      <div className="max-w-2xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="text-center">
          <h2
            id="features-heading"
            className="font-extrabold text-caffeinity-pink"
          >
            Was zeichnet Caffeinity eigentlich aus?
          </h2>
        </div>

        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 mb-16">
            <div className="mx-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div className="mt-6">
                <h2 className="tracking-tight ">Einfache Auswahl</h2>
                <p className="mt-4 text-lg font-normal">
                  Arabica, Robusta, Washed, Natural Honey aus Brasilien oder
                  doch besser Äthiopien, Medium light roast oder doch besser
                  dark roast? Die passende Kaffeewahl ist nicht immer einfach.
                </p>
              </div>
            </div>
            <div className="py-12 sm:mt-16 lg:mt-0">
              <div className="lg:pl-4 lg:m-0 lg:relative">
                <StaticImage
                  src="../images/nathan-dumlao-keTvQFtMYuc-unsplash.jpg"
                  alt="CafeLatte zu Hause Siebträger Waage"
                  FULL_WIDTH
                />
              </div>
            </div>
          </div>

          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 mb-32">
            <div className="mx-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div className="mt-6">
                <h2 className="font-bold tracking-tight ">Lokale Röster</h2>
                <p className="mt-4 text-lg font-normal">
                  Wir arbeiten arbeiten ausschließlich mit lokalen Röstern
                  zusammen, deren Leidenschaft und Handwerk Rösten ist. Unsere
                  Röster beziehen ihren Grünkaffee direkt von den Bauern im
                  Anbaugebiet. Somit stellen Röster und Caffeinity sicher, dass
                  diese auch Fair bezahlt werden und die Bohnen-Qualität unseren
                  Ansprüchen entspricht.
                </p>
                <p className="mt-4 text-lg font-normal">
                  Unsere Kaffees schmecken nicht nur gut, sie “tun” auch gutes.
                </p>
              </div>
            </div>
            <div className="py-12 sm:mt-16 lg:mt-0">
              <div className="lg:pl-4 lg:m-0 lg:relative">
                <StaticImage
                  src="../images/battlecreek-coffee-roasters-IbZFP9eAIic-unsplash.jpg"
                  alt="Lokaler Roester fuer Caffeinity"
                  FULL_WIDTH
                />
              </div>
            </div>
          </div>

          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 mb-32">
            <div className="mx-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div className="mt-6">
                  <h2 className="font-bold tracking-tight ">Zubereitung</h2>
                  <p className="mt-4 text-lg font-normal">
                    Die Zubereitung zu Hause ist nicht immer einfach und die
                    Extraktion eines guten Espresso, oder der perfekte
                    Milchschaum ist nicht immer einfach. Daher unterstützen wir
                    dich, damit du deine Barista Skills auf das nächste Level
                    bringen kannst.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="lg:pl-4 lg:m-0 lg:relative">
                <StaticImage
                  src="../images/nathan-dumlao-vbt-Fp3b5FA-unsplash.jpg"
                  alt="Caffeinity ist geeigente fuer unterschiedliche Zubereitungsarten"
                  FULL_WIDTH
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
