import React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductDescription from "../components/productDescription"
import InfoSection from "../components/infoSection"
import FeatureSection from "../components/featureSection"
import ContactSection from "../components/contactSection"
import { MailchimpSubscribe } from "../components/mailchimpSubscribe"
import InstagramFeed from "../components/instagramFeed"
import MyHeroSection from "../components/myHeroSection"
import PartnerSection from "../components/partnerSection"

const IndexPage = () => (

  <Layout>
    <Seo title="Home" />

    {/* Hero card */}
    <MyHeroSection/>

      <PartnerSection />
    {/*
    <div className="relative shadow-xl sm:overflow-hidden">
      <div className="absolute inset-0 ">
        <StaticImage src="../images/nathan-dumlao-XOhI_kW_TaM-unsplash.jpg"
                     alt="Caffeinity title image coffee beans"

        />
        <div className="absolute inset-0 bg-gray-300 mix-blend-multiply" />
      </div>
      <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
        <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
          Wir kennen die passenden Kaffeebohnen für deinen Geschmack & Maschine.
        </h1>
        <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
          <a
            href="https://forms.gle/XPgEwubavEKs86rU9"
            className="btn-primary "
          >
            Caffeinity jetzt testen
          </a>

        </div>
      </div>
    </div>*/}


    {/*<TheTasteOf />*/}

    {/*INFO SECTION*/}
    {/*Full-width on mobile, constrained with padded content above*/}



    <ProductDescription />

    <FeatureSection />

    <InfoSection textHeading="Kaffee ist so individuell wie du!"
                 textSubtitle="Anhand deiner individuellen Präferenzen wählen wir aus unseren hochwertigen Speciality- als auch Bio-Kaffees den für dich am besten passenden Kaffee aus. Du musst dir keine Gedanken um Sorten, Aufbereitung und Herkunft machen." />

    <InstagramFeed />

    <MailchimpSubscribe />

    <ContactSection />

  </Layout>
)

export default IndexPage
