import React from "react"

import { MailIcon } from "@heroicons/react/outline"
import { graphql, useStaticQuery } from "gatsby"

export default function ContactSection() {

  const data = useStaticQuery(graphql`
    query SiteContactQuery {
      site {
        siteMetadata {
          email
        }
      }
    }
  `)
  return (
    <div className="max-w-7xl mx-auto py-32 px-8 sm:px-6 lg:px-8 text-caffeinity-green-dark">
      <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">

        <div className="text-center">
          <h2>Kontakt</h2>
          <p>Alle Kontaktanfragen, ob Privatpersonen oder Röster einfach und bequem per Email.</p>
        </div>

        <div>
          <dl className="mt-8 space-y-6">
            <dt>
              <span className="sr-only">Email</span>
            </dt>
            <dd className="text-base text-center">
              <MailIcon className="w-6 h-6 inline" aria-hidden="true" />
              <a href={`mailto:${data.site.siteMetadata.email}`}>
                <span className="ml-3">{data.site.siteMetadata.email}</span>
              </a>
            </dd>

          </dl>

        </div>

      </div>
    </div>


  )
}