import React from "react"
import { StaticImage } from "gatsby-plugin-image"


function PartnerSection() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-center text-caffeinity-green-light">
          Unsere Top Partner
        </h2>
        <p className="text-center text-caffeinity-green-dark mt-4"> Wir sind sehr stolz, dass wir mit so großartigen Speciality-Röstern und -Cafés zusammenarbeiten dürfen welche großen Wert auf Bio und Direct Trade legen.</p>
        <div className="mt-6 grid grid-cols-4 gap-0.2 md:grid-cols-7 lg:mt-8">
          <a href="https://gota.coffee" target="_blank" rel="noreferrer" className="col-span-1 flex justify-center py-8 px-8">
            <StaticImage src="../images/partner/GOTA_Logo.jpg"
                         alt="Caffeinity Kaffeeröster Partner GOTA"
                         imgClassName="w-full h-full object-cover"
                         className="my-auto"
            />
          </a>
          <a href="https://suessmund-kaffee.com" target="_blank" rel="noreferrer" className="col-span-1 flex justify-center py-8 px-8">
            <StaticImage src="../images/partner/kaffee_suessmund_wien_logo.png"
                         alt="Caffeinity Kaffeeröster Partner Süßmund"
                         imgClassName="w-full h-full object-cover"
                         className="my-auto"
            />
          </a>
          <a href="https://bohnenspiel.at" target="_blank" rel="noreferrer" className="col-span-1 flex justify-center py-8 px-8">
            <StaticImage src="../images/partner/bohnenspiel-kaffee-logo.png"
                         alt="Caffeinity Kaffeeröster Partner Bohnenspiel"
                         imgClassName="w-full h-full object-cover"
                         className="my-auto"
            />
          </a>
          <a href="https://deluke.coffee" target="_blank" rel="noreferrer" className="col-span-1 flex justify-center py-8 px-8">
            <StaticImage src="../images/partner/deluke-coffee-logo.png"
                         alt="Caffeinity Kaffeeröster Partner DeLuke Coffee"
                         imgClassName="w-full h-full object-cover"
                         className="my-auto"
            />
          </a>
          <a href="https://kouncoffee.at" target="_blank" rel="noreferrer" className="col-span-1 flex justify-center py-8 px-8">
            <StaticImage src="../images/partner/koun-coffee-logo.svg"
                         alt="Caffeinity Kaffeeröster Partner Koun coffee"
                         imgClassName="w-full h-full object-cover"
                         className="my-auto"
            />
          </a>
          <a href="https://himmelsrosterei.at" target="_blank" rel="noreferrer" className="col-span-1 flex justify-center py-8 px-8">
            <StaticImage src="../images/partner/himmels-kaffeeroesterrei-logo.png"
                         alt="Caffeinity Kaffeeröster Partner Himmels kaffeerösterei"
                         imgClassName="w-full h-full object-cover"
                         className="my-auto"
            />
          </a>
          <a href="https://www.kaffeefabrik.at" target="_blank" rel="noreferrer" className="col-span-1 flex justify-center py-8 px-8">
            <StaticImage src="../images/partner/kaffeefabrik-kaffee-logo.png"
                         alt="Caffeinity Kaffeeröster Partner Kaffeefabrik"
                         imgClassName="w-full h-full object-cover"
                         className="my-auto"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default PartnerSection